<template>
  <b-overlay :show="isFetching">
    <b-row class="my-3">
      <b-col
        v-for="(widget , key) in widgets"
        :key="key"
      >
        <b-card class="p-0 m-0 h-100">
          <div class="media">
            <div class="media-body">
              <span class="text-muted text-uppercase font-size-12 font-weight-bold">
                {{ widget.name }}
              </span>
              <h2 class="mb-0">{{ widget.value | money }}</h2>
            </div>
            <div class="align-self-center">
              <span>
                <feather
                  :type="`${widget.icon}`"
                  class="icon-lg align-middle icon-dual-primary"
                  :class="{
                    'icon-dual-warning': key === 1,
                    'icon-dual-success': key === 2,
                }"
                ></feather>
              </span>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-card>
      <div class="d-flex justify-content-between align-items-center">
        <!-- <label class="d-inline-flex align-items-center">
          <FilterAgentsDropdown
            field-name="id"
            @update="(val) => filterAgent = val"
          />
        </label> -->
      </div>
      <!-- Table -->
      <b-table
        :items="gamesList"
        :fields="fields"
        responsive
        show-empty
      >
        <template #cell(#)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(total_af_credit)="data">
          {{ data.value | currency }}
        </template>
        <template #cell(total_bet_credit)="data">
          {{ data.value | currency }}
        </template>

        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <template #empty="">
          <p class="text-center text-muted">{{ $t('messages.nothing_here')}}</p>
        </template>
      </b-table>
    </b-card>
  </b-overlay>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  name: 'AffiliateReport',
  components: {
    // FilterAgentsDropdown: () =>
    //   import('../../../components/agents/filter-agents-dropdown'),
  },
  data() {
    return {
      filterAgent: '',
      fields: [
        '#',
        {
          key: 'game_type',
          label: this.$t('games.name'),
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'total_af_credit',
          label: `${this.$t('affiliate.total_af_credit')}`,
          class: 'text-right',
          thStyle: {
            minWidth: '160px',
          },
        },
        {
          key: 'total_bet_credit',
          label: `${this.$t('affiliate.total_bet_credit')}`,
          class: 'text-right',
          thStyle: {
            minWidth: '150px',
          },
        },
      ],
    }
  },
  computed: {
    ...mapState({
      isFetching: (state) => state.affiliate.isFetchingAffiliateSummary,
    }),
    ...mapGetters(['affiliateSummary']),
    gamesList() {
      return this.affiliateSummary.games || []
    },
    widgets() {
      return [
        {
          icon: 'users',
          name: `${this.$t('affiliate.tabs.members')}`,
          value: this.affiliateSummary.total_member,
        },
        {
          icon: 'dollar-sign',
          name: `${this.$t('affiliate.tabs.credits')}`,
          value: this.affiliateSummary.credit,
        },
        {
          icon: 'database',
          name: `${this.$t('affiliate.tabs.total_credit')}`,
          value: this.affiliateSummary.total_credit,
        },
      ]
    },
  },
  watch: {
    filterAgent() {
      this.fetchData()
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions(['fetchAffiliateSummary']),
    fetchData() {
      this.fetchAffiliateSummary()
    },
  },
}
</script>